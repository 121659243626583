import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { DATA } from "../components/data"

const ProductsPage = () => {
  const { products } = DATA

  return (
    <Layout>
      <SEO
        title={`Produkty`}
        description={`Produkty - Jarglass`}
      />

      <div className="product-bg">
        <div className="container-fluid">
          <div className="col-md-5 col-xs-12">
            <div className="product-text">
              <img
                className="product-logo-img img-responsive"
                alt="Illumi PRO Logo"
                src="../img/Illumi pro.png"
              />
              <div className="line-about-lg"></div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row m-b-md">
            {products.items.map((item) => (
              <div className="col-lg-6 col-md-12 col-xs-12">
                <img
                  className="product-img"
                  onMouseOver={(e) =>
                    (e.currentTarget.src = item.hover_img_url)
                  }
                  onMouseOut={(e) => (e.currentTarget.src = item.img_url)}
                  alt={item.name}
                  src={item.img_url}
                />

                <div className="line-about-lg"></div>

                <p>{item.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ProductsPage
