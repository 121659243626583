export const realisations = [ 
  {
    id: "montownia_lofts_experience-gdansk",
    name: "Montownia-Lofts & Experience",
    location: "Gdańsk",
    year: "2022",
    quantity: "114 szt. luster podświetlanych | 84 szt. luster postarzanych Morena | 84 szt. luster w przedpokojach",
    work_description:
      "Produkcja, dostawa i montaż luster we wszystkich łazienkach, pokojach i przedpokojach apartamentów",
    logo_url: "",
    preview_url:
      "../img/realisations/29_Montownia Gdańsk/Montownia_Gdańsk.jpg",
    outside_url:
      "img/realisations/29_Montownia Gdańsk/Montownia_Gdańsk-outside.jpg",
  },  
  {
    id: "radisson_blu_ostroda",
    name: "Radisson Blu Resort & Conference Center",
    location: "Ostróda",
    year: "2022",
    quantity: "186 szt. luster podświetlanych ILLUMI-PRO Quadro Magnifying",
    work_description:
      "Produkcja, dostawa i montaż luster podświetlanych we wszystkich łazienkach w pokojach hotelowych",
    logo_url:
      "../img/realisations/logotypy hoteli/gotowe/radisson-blu.svg",
    preview_url:
      "../img/realisations/28_Radisson Blu Mazury Ostróda/RadissonBlu_Ostróda.jpg",
    outside_url:
      "img/realisations/28_Radisson Blu Mazury Ostróda/RadissonBlu_Ostróda-outside.jpg",
  }, 
  {
    id: "hbh_swarzedz",
    name: "Hampton by Hilton",
    location: "Swarzędz",
    year: "2022",
    quantity: "102 szt. luster ILLUMI-PRO CIRCLE Ambient RE",
    work_description:
      "Produkcja, dostawa i montaż luster podświetlanych we wszystkich łazienkach w pokojach hotelowych",
    logo_url:
      "../img/realisations/logotypy hoteli/gotowe/hampton-by-hilton.svg",
    preview_url:
      "../img/realisations/27_Hampton by Hilton Swarzedz/HbH_Swarzedz.jpg",
    outside_url:
      "img/realisations/27_Hampton by Hilton Swarzedz/HbH_Swarzedz-outside.jpg",
  },
  {
    id: "hbh_bialystok",
    name: "Hampton by Hilton",
    location: "Białystok",
    year: "2021",
    quantity: "122 szt. luster ILLUMI-PRO CIRCLE Ambient RE",
    work_description:
      "Produkcja, dostawa i montaż luster podświetlanych we wszystkich łazienkach w pokojach hotelowych",
    logo_url:
      "../img/realisations/logotypy hoteli/gotowe/hampton-by-hilton.svg",
    preview_url:
      "../img/realisations/26_Hampton by Hilton Białystok/HbH_Białystok.jpg",
    outside_url:
      "img/realisations/26_Hampton by Hilton Białystok/HbH_Białystok-outside.png",
  },
  {
    id: "hbh_balice",
    name: "Hampton by Hilton",
    location: "Balice",
    year: "2021",
    quantity: "173 szt. luster ILLUMI-PRO CIRCLE Ambient RE",
    work_description:
      "Produkcja, dostawa i montaż luster podświetlanych we wszystkich łazienkach w pokojach hotelowych",
    logo_url:
      "../img/realisations/logotypy hoteli/gotowe/hampton-by-hilton.svg",
    preview_url:
      "../img/realisations/25_Hampton by Hilton Balice/HbH_Balice.jpg",
    outside_url:
      "img/realisations/25_Hampton by Hilton Balice/HbH_Balice-outside.jpg",
  },
  {
    id: "hbh_olsztyn",
    name: "Hampton by Hilton",
    location: "Olsztyn",
    year: "2020",
    quantity: "105 szt. luster ILLUMI-Pro Circle Ambient",
    work_description:
      "Produkcja, dostawa i montaż luster podświetlanych we wszystkich łazienkach w pokojach hotelowych",
    logo_url:
      "../img/realisations/logotypy hoteli/gotowe/hampton-by-hilton.svg",
    preview_url:
      "../img/realisations/24_Hampton by Hilton Olsztyn/Hbh_Olsztyn.jpg",
    outside_url:
      "img/realisations/24_Hampton by Hilton Olsztyn/Hbh_Olsztyn_outside.jpg",
  },
  {
    id: "radisson_zakopane",
    name: "Radisson Blu",
    location: "Zakopane",
    year: "2019",
    quantity: "231 szt. luster podświetlanych",
    work_description:
      "Produkcja, dostawa i montaż luster podświetlanych we wszystkich łazienkach w pokojach hotelowych i w apartamentach",
    logo_url: "../img/realisations/logotypy hoteli/gotowe/radisson-blu.svg",
    preview_url: "../img/realisations/1_Radsson Zakopane/Radisson_Zakopane.jpg",
    outside_url:
      "img/realisations/1_Radsson Zakopane/Radisson_Zakopane-outside.jpg",
  },
  {
    id: "hbh_kalisz",
    name: "Hampton by Hilton",
    location: "Kalisz",
    year: "2019",
    quantity: "97 szt. luster illumiPro 023",
    work_description:
      "Produkcja, dostawa i montaż luster podświetlanych we wszystkich łazienkach w pokojach hotelowych",
    logo_url:
      "../img/realisations/logotypy hoteli/gotowe/hampton-by-hilton.svg",
    preview_url:
      "../img/realisations/2_Hampton by Hilton Kalisz/HbH_Kalisz.jpg",
    outside_url:
      "img/realisations/2_Hampton by Hilton Kalisz/HbH_Kalisz_outside.jpg",
  },
  {
    id: "hbh_oswiecim",
    name: "Hampton by Hilton",
    location: "Oświęcim",
    year: "2018",
    quantity: "118 szt. luster illumiPro 023",
    work_description:
      "Produkcja i dostawa luster podświetlanych do wszystkich łazienek w pokojach hotelowych",
    logo_url:
      "../img/realisations/logotypy hoteli/gotowe/hampton-by-hilton.svg",
    preview_url:
      "../img/realisations/3_Hampton by Hilton Oświecim/Oswiecim.jpg",

    outside_url:
      "img/realisations/3_Hampton by Hilton Oświecim/Oswiecim_outside.jpg",
  },
  {
    id: "hbh_wwa_mokotow",
    name: "Hampton by Hilton",
    location: "Warszawa Mokotów",
    year: "2018",
    quantity: "161 szt. luster illumiPro 023",
    work_description:
      "Dostawa luster podświetlanych do wszystkich łazienek w pokojach hotelowych",
    logo_url:
      "../img/realisations/logotypy hoteli/gotowe/hampton-by-hilton.svg",
    preview_url:
      "../img/realisations/4_Hampton by Hilton Warszawa Mokotow/Hbh_Warszawa_Mokotów.jpg",

    outside_url:
      "img/realisations/4_Hampton by Hilton Warszawa Mokotow/Hbh_Warszawa_Mokotów_outside.jpg",
  },
  {
    id: "hbh_gda_old_town",
    name: "Hampton by Hilton",
    location: "Gdańsk - Stare Miasto",
    year: "2018",
    quantity: "172 szt. luster illumiPro 023",
    work_description:
      "Produkcja i dostawa luster podświetlanych do wszystkich łazienek w pokojach hotelowych",
    logo_url:
      "../img/realisations/logotypy hoteli/gotowe/hampton-by-hilton.svg",
    preview_url:
      "../img/realisations/5_Hampton by Hilton Gdansk Old Town/Hampton_by_Hilton_Gdansk_Old_Town.jpg",

    outside_url:
      "img/realisations/5_Hampton by Hilton Gdansk Old Town/Hampton_by_Hilton_Gdansk_Old_Town_Outside.jpg",
  },
  {
    id: "hbh_gda_oliwa",
    name: "Hampton by Hilton",
    location: "Gdańsk - Oliwa",
    year: "2017",
    quantity: "102 szt. luster illumiPro 023",
    work_description:
      "Produkcja i dostawa luster podświetlanych do wszystkich łazienek w pokojach hotelowych",
    logo_url:
      "../img/realisations/logotypy hoteli/gotowe/hampton-by-hilton.svg",
    preview_url:
      "../img/realisations/6_Hampton by Hilton Gdans Oliwa/HbH_Gdansk_Oliwa.jpg",

    outside_url:
      "img/realisations/6_Hampton by Hilton Gdans Oliwa/HbH_Gdansk_Oliwa_Outside.jpg",
  },
  {
    id: "hbh_lublin",
    name: "Hampton by Hilton",
    location: "Lublin",
    year: "2017",
    quantity: "120 szt. luster illumiPro 023",
    work_description:
      "Produkcja, dostawa i montaż luster podświetlanych do wszystkich łazienek w pokojach hotelowych",
    logo_url:
      "../img/realisations/logotypy hoteli/gotowe/hampton-by-hilton.svg",
    preview_url:
      "../img/realisations/7_Hampton by Hilton Lublin/Hampton_by_Hilton.jpg",
    outside_url:
      "img/realisations/7_Hampton by Hilton Lublin/Hampton_by_Hilton_outside.jpg",
  },
  {
    id: "sheraton_grand",
    name: "Sheraton Grand",
    location: "Kraków",
    year: "2016",
    quantity: "230 szt. luster illumiPro Quadro",
    work_description:
      "Produkcja i dostawa luster podświetlanych do wszystkich łazienek w pokojach hotelowych",
    logo_url:
      "../img/realisations/logotypy hoteli/gotowe/new-sheraton-seeklogo.com.svg",
    preview_url: "../img/realisations/8_SHERATON KRAKOW/Sheraton_Krakow_2.jpg",
    outside_url:
      "img/realisations/8_SHERATON KRAKOW/Sheraton_Krakow_Outside.jpg",
  },
  {
    id: "double_tree_krakow",
    name: "Double Tree by Hilton",
    location: "Kraków",
    year: "2015",
    quantity: "230 szt. luster illumiPro 023",
    work_description:
      "Produkcja, dostawa i montaż luster podświetlanych we wszystkich łazienkach w pokojach hotelowych i łazienkach ogólnodostępnych",
    logo_url:
      "../img/realisations/logotypy hoteli/gotowe/doubletree-by-hilton-lodz_1.svg",
    preview_url:
      "../img/realisations/9_Double Tree by Hilton Krakow/Double_Tree_by_Hilton_ nKrakow.jpg",
    outside_url:
      "img/realisations/9_Double Tree by Hilton Krakow/Double_Tree_by_Hilton_ nKrakow_outside.jpg",
  },
  {
    id: "hbh_krakow",
    name: "Hampton by Hilton",
    location: "Kraków",
    year: "2015",
    quantity: "159 szt. luster illumiPro 023",
    work_description:
      "Produkcja, dostawa i montaż luster podświetlanych we wszystkich łazienkach w pokojach hotelowych i łazienkach ogólnodostępnych",
    logo_url:
      "../img/realisations/logotypy hoteli/gotowe/hampton-by-hilton.svg",
    preview_url:
      "../img/realisations/10_Hampton by Hilton Krakow/Hampton_by_Hilton_Krakow.jpg",
    outside_url:
      "img/realisations/10_Hampton by Hilton Krakow/hampton_krakow_outside.jpg",
  },
  {
    id: "hilton_garden_inn_rzeszow",
    name: "Hilton Garden Inn",
    location: "Rzeszów",
    year: "2014",
    quantity: "101 szt. luster illumiPro 023",
    work_description:
      "Produkcja, dostawa i montaż luster podświetlanych we wszystkich łazienkach w pokojach hotelowych i łazienkach ogólnodostępnych",
    logo_url:
      "../img/realisations/logotypy hoteli/gotowe/Hilton_Garden_Inn.svg",
    preview_url:
      "../img/realisations/11_Garden InnRzeszów/Garden_Inn_rzeszow.jpg",
    outside_url:
      "img/realisations/11_Garden InnRzeszów/Garden_Inn_rzeszow_outside.jpg",
  },
  {
    id: "tatra_chalet",
    name: "Tatra Chalet",
    location: "Zakopane",
    year: "2011",
    quantity: null,
    work_description:
      "Produkcja i dostawa luster podświetlanych w ramach drewnianych do łazienek w pokojach hotelowych",
    logo_url: null,
    preview_url:
      "../img/realisations/15_Tatra Chalet Zakopane/Tatra_Chalet_1.jpg",
    outside_url:
      "img/realisations/15_Tatra Chalet Zakopane/Tatra_Chalet_Zakopane_outside1.jpg",
  },
  {
    id: "hotel_prezydencki_rze",
    name: "Hotel Prezydencki",
    location: "Rzeszów",
    year: "2011",
    quantity: null,
    work_description:
      "Produkcja, dostawa i montaż luster we wszystkich łazienkach w pokojach hotelowych",
    logo_url: null,
    preview_url:
      "../img/realisations/17_Hotel Prezydencki_Rzeszów/Hotel_Prezydencki_Rzeszów.jpg",
    outside_url:
      "img/realisations/17_Hotel Prezydencki_Rzeszów/Hotel_Prezydencki_Rzeszów_Outside.jpg",
  },
  {
    id: "mercure_grand_wwa",
    name: "Mercure Grand",
    location: "Warszawa",
    year: "2008",
    quantity: null,
    work_description:
      "Produkcja, dostawa i montaż luster we wszystkich 299 łazienkach w pokojach hotelowych. Dostawa i montaż luster w przestrzeniach ogólnodostępnych",
    logo_url:
      "../img/realisations/logotypy hoteli/gotowe/mercure-hotels-seeklogo.com_1.svg",
    preview_url: "../img/realisations/12_Mercure Warszawa/Mercure_Warszawa.jpg",
    outside_url:
      "img/realisations/12_Mercure Warszawa/Mercure_Warszawa_outside.jpg",
  },
  {
    id: "hilton_wwa",
    name: "Hilton Warsaw Hotel and Convention Centre",
    location: "Warszawa",
    year: "2007",
    quantity: "314 szt. luster illumiPro 023",
    work_description:
      "Produkcja, dostawa i montaż luster podświetlanych we wszystkich łazienkach w pokojach hotelowych i łazienkach ogólnodostępnych",
    logo_url:
      "../img/realisations/logotypy hoteli/gotowe/Hilton_International.svg",
    preview_url:
      "../img/realisations/13_Hilton Warszawa Grzybowska/Hilton_Warszawa.jpg",
    outside_url:
      "img/realisations/13_Hilton Warszawa Grzybowska/Hilton_Warszawa_Outside.jpg",
  },
  {
    id: "mamaison_le_regina_wwa",
    name: "Mamaison Hotel Le Regina",
    location: "Warszawa",
    year: "2004",
    quantity: null,
    work_description: "Produkcja i dostawa luster podświetlanych z logo hotelu",
    logo_url: "../img/realisations/logotypy hoteli/gotowe/Le Regina.svg",
    preview_url:
      "../img/realisations/16_Le_Regina_Warszawa/Le_Regina_Warsaw.jpg",
    outside_url:
      "img/realisations/16_Le_Regina_Warszawa/MaMaison-Hotel-Le-Regina-Warsaw_1280x768.jpg",
  },
  {
    id: "novotel_wilno",
    name: "Novotel",
    location: "Wilno (Litwa)",
    year: "2004",
    quantity: null,
    work_description:
      "Produkcja, dostawa i montaż luster łazienkowych i pokojowych we wszystkich pokojach",
    logo_url: "../img/realisations/logotypy hoteli/gotowe/Novotel.svg",
    preview_url: "../img/realisations/19_Novotel Wilno/Novotel_Wilno.jpg",
    outside_url: "img/realisations/19_Novotel Wilno/Novotel_Wilno.jpg",
  },
  {
    id: "ibis_wwa",
    name: "IBIS",
    location: "Warszawa – Stare Miasto",
    year: "2003",
    quantity: null,
    work_description:
      "Produkcja, dostawa i montaż luster łazienkowych i pokojowych w 333 pokojach",
    logo_url: "../img/realisations/logotypy hoteli/gotowe/ibis-hotels.svg",
    preview_url:
      "../img/realisations/18_IBIS_Warszawa/IBIS STARE MIASTO WARSZAWA.jpg",
    outside_url:
      "img/realisations/18_IBIS_Warszawa/IBIS STARE MIASTO WARSZAWA.jpg",
  },
  {
    id: "ibb_grand_hotel_lublinianka",
    name: "IBB GRAND LUBLINIANKA",
    location: "Lublin",
    year: "2002",
    quantity: null,
    work_description:
      "Produkcja, dostawa i montaż luster łazienkowych i pokojowych we wszystkich pokojach",
    logo_url: "../img/realisations/logotypy hoteli/gotowe/IBB hotels ok.svg",
    preview_url:
      "../img/realisations/14_ IBB Grand Lublinianka/IBB_Grand_Lublinianka_1.jpg",
    outside_url:
      "img/realisations/14_ IBB Grand Lublinianka/IBB_Grand_Lublinianka_Outside.jpg",
  },
  {
    id: "ibis_krakow",
    name: "IBIS",
    location: "Kraków",
    year: "2002",
    quantity: null,
    work_description:
      "Produkcja, dostawa i montaż luster w strefach ogólnodostępnych",
    logo_url: "../img/realisations/logotypy hoteli/gotowe/ibis-hotels.svg",
    preview_url: "../img/realisations/20_Ibis Krakow/IBIS KRAKOW.jpg",
    outside_url: "img/realisations/20_Ibis Krakow/IBIS KRAKOW.jpg",
  },
  {
    id: "novotel_krakow",
    name: "Novotel",
    location: "Kraków Centrum",
    year: "2002",
    quantity: null,
    work_description:
      "Produkcja, dostawa i montaż luster w strefach ogólnodostępnych",
    logo_url: "../img/realisations/logotypy hoteli/gotowe/Novotel.svg",
    preview_url: "../img/realisations/21_Novotel Krakow/NOVOTEL KRAKOW.jpg",
    outside_url: "img/realisations/21_Novotel Krakow/NOVOTEL KRAKOW.jpg",
  },
  {
    id: "ibis_szczecin",
    name: "IBIS",
    location: "Szczecin",
    year: "2002",
    quantity: null,
    work_description:
      "Produkcja, dostawa i montaż luster w strefach ogólnodostępnych",
    logo_url: "../img/realisations/logotypy hoteli/gotowe/ibis-hotels.svg",
    preview_url: "../img/realisations/22_IBIS Szczecin/IBIS SZCZECIN.jpg",
    outside_url: "img/realisations/22_IBIS Szczecin/IBIS SZCZECIN.jpg",
  },
  {
    id: "novotel_szczecin",
    name: "Novotel",
    location: "Szczecin",
    year: "2002",
    quantity: null,
    work_description:
      "Produkcja, dostawa i montaż luster w strefach ogólnodostępnych",
    logo_url: "../img/realisations/logotypy hoteli/gotowe/Novotel.svg",
    preview_url: "../img/realisations/23_NOVOTEL Szczecin/NOVOTEL SZCZECIN.jpg",
    outside_url: "img/realisations/23_NOVOTEL Szczecin/NOVOTEL SZCZECIN.jpg",
  },
]
