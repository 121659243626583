export const products = {
  opis_1: "costam",
  items: [
    {
      name: "",
      img_url: "../img/products/1.jpg",
      hover_img_url: "../img/products/1_1.jpg",
    },

    {
      name: "",
      img_url: "../img/products/2.jpg",
      hover_img_url: "../img/products/2_1.jpg",
    },

    {
      name: "",
      img_url: "../img/products/3.jpg",
      hover_img_url: "../img/products/3_1.jpg",
    },

    {
      name: "",
      img_url: "../img/products/4.jpg",
      hover_img_url: "../img/products/4_1.jpg",
    },

    {
      name: "",
      img_url: "../img/products/5.jpg",
      hover_img_url: "../img/products/5_1.jpg",
    },

    {
      name: "",
      img_url: "../img/products/6.jpg",
      hover_img_url: "../img/products/6_1.jpg",
    },

    {
      name: "",
      img_url: "../img/products/7.jpg",
      hover_img_url: "../img/products/7_1.jpg",
    },

    {
      name: "",
      img_url: "../img/products/8.jpg",
      hover_img_url: "../img/products/8_1.jpg",
    },

    {
      name: "",
      img_url: "../img/products/9.jpg",
      hover_img_url: "../img/products/9_1.jpg",
    },

    {
      name: "",
      img_url: "../img/products/10.jpg",
      hover_img_url: "../img/products/10_1.jpg",
    },

    {
      name: "",
      img_url: "../img/products/11.jpg",
      hover_img_url: "../img/products/11_1.jpg",
    },

    {
      name: "",
      img_url: "../img/products/12.jpg",
      hover_img_url: "../img/products/12_1.jpg",
    },

    {
      name: "",
      img_url: "../img/products/13.jpg",
      hover_img_url: "../img/products/13_1.jpg",
    },

    {
      name: "",
      img_url: "../img/products/14.jpg",
      hover_img_url: "../img/products/14_1.jpg",
    },

    {
      name: "",
      img_url: "../img/products/15.jpg",
      hover_img_url: "../img/products/15_1.jpg",
    },

    {
      name: "",
      img_url: "../img/products/16.jpg",
      hover_img_url: "../img/products/16_1.jpg",
    },

    {
      name: "",
      img_url: "../img/products/17.jpg",
      hover_img_url: "../img/products/17_1.jpg",
    },
  ],
}
